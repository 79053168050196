<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="primary" class="filter-item" size="small" @click="handleCreate">新增</el-button>

      <el-select v-model="listQuery.is_on" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
        <el-option label="下架" :value="0" />
        <el-option label="正常" :value="1" />
      </el-select>

      <el-button type="primary" class="filter-item" size="small" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" size="small" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="优惠券类型" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon.coupon_type === 1">无门槛</span>
          <span v-else-if="scope.row.coupon.coupon_type === 2">满{{scope.row.coupon.minimum}}可用</span>
          <span v-else-if="scope.row.coupon.coupon_type === 3">商品优惠</span>
        </template>
      </el-table-column>

      <el-table-column label="优惠券使用范围" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon.coupon_scope === 1">全平台</span>
          <span v-else>{{scope.row.coupon.shop.shop_name}}</span>
        </template>
      </el-table-column>

      <el-table-column label="优惠金额" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon.coupon_type === 3">
            <template v-if="scope.row.coupon.discount_mode === 1">
              商品优惠{{scope.row.coupon.discount_num}}折
            </template>
            <template v-else-if="scope.row.coupon.discount_mode === 2">
              商品立减{{scope.row.coupon.discount}}元
            </template>
          </span>
          <span v-else>{{ scope.row.coupon.discount }}</span>
        </template>
      </el-table-column>

      <el-table-column label="有效期开始时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.coupon.start_time }}
        </template>
      </el-table-column>

      <el-table-column label="有效期结束时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.coupon.end_time }}
        </template>
      </el-table-column>

      <el-table-column label="优惠券数量" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.num }}
        </template>
      </el-table-column>

      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>

      <el-table-column label="库存" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>

      <el-table-column label="权重" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>

      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on === 1" type="success">正常</el-tag>
          <el-tag v-else-if="scope.row.is_on === 0" type="danger">下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50, 100]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="180px">
        <el-form-item label="优惠券id" prop="coupon_id">
          <el-select size="small" v-model="form.coupon_id" filterable style="width: 100%;max-width: 220px;">
            <el-option v-for="item in couponList" :key="item.id" :label="item.id" :value="item.id">
              <div style="display: flex;align-items:center;text-align:center;">
                <span>{{ item.id }}</span>

                <span>---</span>
                <span v-if="item.coupon_type === 1">无门槛 </span>
                <span v-else-if="item.coupon_type === 2">满{{item.minimum}}可用</span>
                <span v-else-if="item.coupon_type === 3">商品优惠</span>

                <span>---</span>
                <span v-if="item.coupon_scope === 1">全平台</span>
                <span v-else>{{item.shop.shop_name}}</span>

                <span>---</span>
                <span v-if="item.coupon_type === 3">
                  <template v-if="item.discount_mode === 1">
                      商品优惠{{item.discount_num}}折
                    </template>
                    <template v-else-if="item.discount_mode === 2">
                      商品立减{{item.discount}}元
                    </template>
                </span>
                <span v-else>优惠立减{{ item.discount }}元</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="优惠券数量" prop="num">
          <el-input-number size="small" v-model="form.num" :min="1" :precision="0" style="width: 100%;max-width: 220px;"></el-input-number>
        </el-form-item>

        <el-form-item label="价格" prop="price">
          <el-input-number size="small" v-model="form.price" :min="0.01" :precision="2" style="width: 100%;max-width: 220px;"></el-input-number>
        </el-form-item>

        <el-form-item label="库存" prop="stock">
          <el-input-number size="small" v-model="form.stock" :min="0" :precision="0" style="width: 100%;max-width: 220px;"></el-input-number>
        </el-form-item>

        <el-form-item label="权重" prop="weight">
          <el-input-number size="small" v-model="form.weight" :min="0" :precision="0" style="width: 100%;max-width: 220px;"></el-input-number>
          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*权重越大，排名越前</div>
          </div>
        </el-form-item>

        <el-form-item label="状态" prop="is_on">
          <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          is_on: ""
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          create: "新增",
          update: "修改"
        },
        form: {
          id: "",
          school_id: "",
          coupon_id: "",
          num: 1,
          price: 0,
          stock: 0,
          weight: 0,
          is_on: 1,
        },
        rules: {
          coupon_id: [{ required: true, message: "优惠券不能为空", trigger: "change" }],
          num: [{ required: true, message: "优惠券数量不能为空", trigger: "change" }],
          price: [{ required: true, message: "价格不能为空", trigger: "change" }],
          stock: [{ required: true, message: "库存不能为空", trigger: "change" }],
          weight: [{ required: true, message: "权重不能为空", trigger: "change" }],
          is_on: [{ required: true, message: "状态不能为空", trigger: "change" }],
        },
        btnLoading: false,
        // 优惠券列表
        couponList: null
      };
    },
    created() {
      this.getList();
      this.getCouponList();
    },
    computed: {
      ...mapGetters(["schools", "school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getCouponList();
      },
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/couponPackage/list",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      getCouponList() {
        request({
          url: "/api/backend/couponPackage/couponList",
          method: "get",
          params: {
            page: 1,
            limit: 100000,
            school_id: this.school_id
          }
        }).then(response => {
          this.couponList = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          coupon_id: "",
          num: 1,
          price: 0,
          stock: 0,
          weight: 0,
          is_on: 1,
        };
        this.btnLoading = false;
      },
      handleCreate() {
        this.resetForm();
        this.dialogStatus = "create";
        this.dialogFormVisible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate(); //清除校验结果
        });
      },
      handleUpdate(row) {
        this.resetForm();
        this.form = Object.assign({}, row);
        this.dialogStatus = "update";
        this.dialogFormVisible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate(); //清除校验结果
        });
      },
      saveData() {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          return;
        } else {
          this.form.school_id = this.school_id
        }
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.btnLoading = true;
            request({
              url: "/api/backend/couponPackage/save",
              method: "post",
              data: this.form
            })
            .then(() => {
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.btnLoading = false;
            });
          }
        });
      },
      handleDelete(row) {
        this.$confirm("确定继续该操作", "提示", {
          type: "warning"
        })
        .then(() => {
          request({
            url: "/api/backend/couponPackage/delete",
            method: "post",
            data: { id: row.id }
          }).then(() => {
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.getList();
          });
        })
        .catch(err => {
          console.log(err);
        });
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
